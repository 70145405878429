.sweet-alert {
  margin: auto!important;
  top: unset!important;
  left: unset!important;
  border-radius: 0px!important;
  border: $ag-border!important;
}
.sweet-alert h2 {
    color: $ag-font-color!important;
    font-size: $font-size-h4!important;
}