.modal-header {
    border: 0 none;
}
.modal-content {
    border: 0 none;
    border-radius: 0px !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    border: $ag-modal-border;
}

:root {
    --modal-width: 500px;
    --modal-width-tablet: 100%;
}

.modal-dialog {
    width: var(--modal-width);
    max-width: 100% !important
}

@media(max-width: 1200px) {
    .modal-dialog {
        padding-top: var(--modal-padding-top, 60px);
        width: var(--modal-width-tablet, 500px);
        height: var(--modal-height-tablet, 400px);
        margin: 0 !important;
        padding: 0 !important;
    }

    .modal-content {
        height: 100%;
    }
}

.modal-footer {
    border-top: 0 none;
    padding: 10px 10px;        
}
.modal-footer .modal-footer .btn-default.btn-simple{
    font-weight: 400;
}

.modal.fade .modal-dialog {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
}
.modal.in .modal-dialog {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
}
.modal-small{
    .modal-dialog{
        max-width: 350px;
    }
}
.modal-small{
    .divider{
        margin: 0 auto;
        display: block;
        width: 14px;
        position: relative;
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: $font-paragraph;
    }
    .divider:after{
        position: absolute;
        content: "";
        right: -140px;
        top: 12px;
        height: 1px;
        width: 115px;
        background-color: $light-gray;
    }
    .divider:before{
        position: absolute;
        content: "";
        left: -140px;
        top: 12px;
        height: 1px;
        width: 115px;
        background-color: $light-gray;
    }
    .modal-footer{
        text-align: center;
    }
}
.social-area{
    text-align: center;
    
    .btn-social{
        margin: 0 10px;
    }
}
.modal-backdrop.in {
    opacity: 0.25;
}


