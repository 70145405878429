.page-action-row {
    display: flex;
    justify-content: flex-end;

    .btn-supervisor {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
}

.PO {
    margin-bottom: -41px;
}

.custom-autocomplete {
    border-radius: 0px;
    font-size: 0.7rem;
    height: 30px;
    border: 1px solid grey;
    background-color: #F5F5F5;
    color: #888888;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
    height: 30px !important;
    border: none !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    height: 30px !important;
    border: none !important;
}

.custom-badge {
    background-color: orange;
    color: black;
    padding: 8px;
    margin-right: 5px;
    border-radius: 10px;

    &:hover {
        background-color: darkorange !important;
    }
}

.custom-badge-icon {
    color: black !important;
}

.AddPO {
    margin-top: 18px;
    margin-left: -64px;
}

.addMaping {
    margin-left: 116px;
    margin-top: -33px;
}

.editUser {
    margin-top: -30px;
}

.productCol {
    margin-top: 6px !important;
    padding-left: 6px !important;
}

.PurchaseModal {
    margin-bottom: 20px;
}

.PurchaseModalTable {
    margin-bottom: 10px;
}

.card {
    margin-bottom: 0px;
}

.archived {
    margin-top: -21px;
    margin-left: 20px;
}

.archivedCol {
    margin-left: -17px;
    margin-top: 24px;
}

.details {
    width: 73px;
    margin-top: -39px;
    margin-left: 86px;
}

.username {
    margin-left: -9px;
}

.text-published {
    color: forestgreen;
}

.text-unpublished {
    color: red;
}

// Tabs
// #######
.CloseButton {
    float: right;
    border: none;
}

.item-card-tab {
    min-height: 500px;
}

button {
    border: none !important;
    background-color: white;
    border-radius: 0;
}

.table-data {
    overflow: auto;
    height: 450px !important;
}

.select-dropdown {
    background-color: #FFFFFF;
    border: 1px solid grey;
    border-radius: 0px;
    font-size: 0.7rem;
    color: #565656;
    padding: 8px 12px;
    height: 30px;
    box-shadow: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs {
    //border-color: black;
    //border-bottom: 1px solid black;
    border: none;
    padding-top: 15px;
    font-size: 14px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: black;
    background-color: white;
    border: none;
    border-bottom: $app-tab-border;
    margin-right: 4px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black;
    border: none;
    border-bottom: $app-tab-active-border;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
    color: black;
    border: none;
    border-bottom: $app-tab-hover-border;
}


//########
.modal-content {
    // Styles for the modal content container

    div.row {
        // Styles for the rows under .modal-content
        // Apply your desired styles here
        --bs-gutter-x: 0.8rem !important;
    }
}


.sidebar-mini {
    .wrapper-logo-img {
        display: block;
    }

    .logo-img {
        display: none;
    }

    .sidebar-wrapper {
        &:hover {
            .wrapper-logo-img {
                display: none;
            }

            .logo-img {
                display: block;
            }
        }
    }
}

.locationId {
    margin-top: -24px;
    text-align: center;
}

.ListGroup {
    padding-top: 12px !important;
    width: 40%;
}

.ListGroupItem {
    margin-top: -27px;
    padding-top: 30px;
    margin-left: 34px;
    background-color: skyblue;
    border-radius: 0px !important;
}

.modelName {
    margin-left: 24px;
}

.Add-group {
    margin-bottom: 24px;
}

.DragAndDrop {
    list-style-type: none;
    margin-left: -18px;
}

.edit-group {
    margin-left: 101%;
    margin-top: -48px;
}
//scrollbar
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.elysium-h100 {
    height: 100%;
}

.elysium-h90 {
    height: 90%;
}

.elysium-h80 {
    height: 80%;
}

.elysium-h70 {
    height: 70%;
}

.date-select {
    height: $height-base;
    display: flex;
    justify-content: space-between;

    .rdt {
        width: 100%;
    }

    .form-control {
        height: $height-base;
        border: 1px solid #ccc;
    }
}

.wrapper-logo-img {
    border-bottom: 1px solid black;
    height: 42px !important;
    margin-top: 10px;
    display: none;
}

.date-range {
    height: $height-base;

    .react-datepicker-wrapper {
        width: 100%;

        input {
            width: 100%;
            height: $height-base;
            font-size: 11.2px;
            text-align: left;
            padding-left: 10px;
            color: #888888;
            border: 1px solid grey !important;
            border-radius: 0px;
        }
    }

    .react-datepicker-popper {
        z-index: 9999;
    }
}

.addIcon {
    width: 50%;
}

.item-detail-row {
    width: 24%;
    flex-wrap: initial;
}

.modal-col {
    margin-left: -13px;
}

.custom-select {
    height: $height-base;
    min-width: 100px
}

.selectButton {
    justify-content: center;
    display: flex;
}

.buyer-detail {
    padding-left: 3px;
    z-index: 9999;
}

.add-model {
    margin-left: 3px;
    width: 260px;
    margin-bottom: 10px;
}

.list-group-item {
    border: none;
    display: flex;
    font-size: 0.7rem;
}

.no-Image {
    position: relative;
    top: -38px;
    left: 112px;
}

.logoImage {
    display: flex;
    justify-content: center;
}

.card-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.card-name {
    display: flex;
    align-items: center;
}

.btn-icon {
    padding: 0;
    margin: 0;
}

.btn-edit-section {
    position: relative;
    left: 112px;
    top: -93px;
}

.btn-info {
    color: $ag-font-btn-color !important;
    border: none !important;
    background-color: $app-button-cancel-colour !important;

    &:hover,
    &:focus {
        color: $ag-font-btn-color !important;
        border: none !important;
        background-color: $app-button-cancel-colour !important;
    }
}


.btn-primary {
    color: $ag-font-btn-color !important;
    border: none !important;
    background-color: $app-button-save-colour !important;

    &:hover,
    &:focus {
        color: $ag-font-btn-color !important;
        border: none !important;
        background-color: $app-button-save-colour !important;
    }
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}

.password-btn {
    margin: 0px;
    padding: 6px;
    padding-right: 7px;
    border: $ag-border !important;
    border-left: none !important;

    &:hover,
    &:focus {
        margin: 0px;
        padding: 6px;
        padding-right: 7px;
        border: $ag-border !important;
        border-left: none !important;
    }
}

.saving-spinner {
    max-height: 12px;
    max-width: 12px;
}

.ag-add-btn {
    float: right;
}

.form-check {
    padding-left: 0px;
}

.form-check .form-check-label {
    padding-left: 22px;
    font-size: 0.7rem;
    font-weight: bold;
    color: black;

    .form-check-sign {
        font-weight: initial;

        &::after {
            color: black;
        }
    }
}

.clocking-container {
    height: 80vh;
}

.ag-nav {
    .dropdown-menu {
        width: 210px !important;
    }
}

.ag-sidebar {
    .sidebar-wrapper {

        > ul {
            > li:first-child.nav-item:not(.collapse, .nav-link):hover .nav-link {
                background: unset !important;
                opacity: unset !important;
            }
        }
    }

    .form-check .form-check-sign::before, .form-check .form-check-sign::after {
        color: #ffffff;
    }
}

@media only screen and (max-width: 768px) {
    .clocking-container {
        height: unset;
    }
}

thead {
    position: sticky;
    background: white;
    top: 0px;
}

.sticky-scroll {
    position: sticky;
    background: white;
    z-index: 1;
    top: 0px;
}

.cursor-pointer {
    cursor: pointer;
}

.table-selected-row {
    background: #E3E3E3;
}

.shift-container {
    height: 85vh;

    .row {
        height: 100%;

        .card {
            height: 100%;
            padding-bottom: 20px;

            .card-body {
                height: 90%;

                .shift-table-container {
                    height: 100%;
                    overflow-y: auto;

                    table {
                        tr td {
                            display: table-cell;
                        }

                        tbody tr td a {
                            padding: 0 16px !important;
                            line-height: 1 !important;
                        }
                    }
                }

                .shift-label {
                    padding-top: 12px;
                }

                .row:first-child, .row:nth-child(2), .row:nth-child(3) {
                    height: 7%;
                }

                .row:nth-child(4) {
                    height: 73%;

                    .shift-patterns-table-container {
                        height: 100%;
                        overflow-y: auto;

                        table {
                            tr td {
                                display: table-cell;
                            }
                        }
                    }
                }

                .row:nth-child(5) {
                    height: 6%;
                }
            }
        }
    }
}

.employee-container {
    height: 40vh;

    .row {
        height: 100%;

        .card {
            height: 100%;
            padding-bottom: 20px;

            .card-body {
                height: 90%;

                .employee-table-container {
                    height: 100%;

                    table {
                        tr td {
                            display: table-cell;
                        }
                    }
                }

                .row:first-child, .row:nth-child(2), .row:nth-child(3) {
                    height: 7%;
                }

                .row:nth-child(4) {
                    height: 79%;
                }
            }
        }
    }
}

.card-user .card-body {
    .author {
        margin-top: 40px;
        border-radius: 0 !important;
    }

    min-height: 352px;
}

.fa-plus {
    color: $app-add-icon-color !important;
}

.fa-edit {
    color: $app-edit-icon-color !important;
}

.fa-times {
    color: $app-delete-icon-color !important;
}

input:focus {
    background-color: white !important;
}

.btn-action {
    border: none !important;

    &:hover {
        border: none !important;
    }
}


.login-img img {
    width: 180px;
    top: 1px;
}

.ag_navbar .avatar {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin-right: 0px;
    margin-left: 0px;
    z-index: 5;
    border: 2px solid rgba(255, 255, 255, 0.3);

    img {
        width: 100%;
    }
}

.react-select {
    .react-select__control {
        border: 1px solid grey !important;
        border-radius: 0px;
    }

    .react-select__menu {
        z-index: 9999;

        .react-select__menu-list {
            z-index: 9999;
        }
    }
}

.products_info_modal {
    /*    .modal-dialog {
        max-width: 800px;
        margin: 0 auto;
        padding: 50px 5px 0;
    }*/

    .modal-card {
        margin: 0 0 5px;
    }

    .tab-content {
        .tab-pane {
            padding: 10px 0;
        }
    }

    .card {
        margin: 0;

        .card-body {
            padding: 0px 7px 5px;

            .row {
                margin: 0 -7px !important;

                > div {
                    padding-left: 7px !important;
                    padding-right: 7px !important;
                }
            }

            .form-group {
                .form-control {
                    margin: 0 !important;
                }
            }
        }
    }
}

.inventory-table .td-actions .btn-action {
    margin: 0;
    padding: 0;
}

.buyerCollection {
    background-color: #888888 !important;
}

.buyerCollection:hover {
    background-color: #FFA500 !important;
}

.location_list_view {
    padding: 13px 0 0;
    margin-bottom: -10px;
}

.count_list {
    padding: 4px;
}

.LocationExport {
    margin-top: -20px;
    margin-left: 19px;
}

.count_list > div {
    height: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    min-width: 22px;
    justify-content: center;
    margin-right: 5px;
    font-size: 15px;
}

.location_list_view .item_name {
    text-align: left;
    font-size: 14px;
    padding: 0 3px;
    line-height: 16px;
}

.Sizegroup {
    margin-right: 10px;
}

@media (max-width:575px) {
    .products_info_modal {
        .nav-tabs {
            .nav-link {
                padding: 6px 14px;
                font-size: 13px;
            }
        }

        .card {
            .card-body {
                padding: 0px 2px 3px;
            }
        }
    }
}

@media (max-width:370px) {
    .products_info_modal {
        .nav-tabs {
            .nav-link {
                padding: 6px 10px;
                font-size: 13px;
                margin-right: 3px;
            }
        }
    }
}

@media (max-width:991px) {
    .card-user {
        .card-body {
            .author {
                margin-top: 40px;
                margin-bottom: 5px;
            }
        }
    }
}

@media (max-width:767px) {
    .card-user {
        .card-body {
            .author {
                margin-top: 18px;
            }
        }
    }
}

@media (min-width: 992px) {
    .discountvalue {
        padding-right: 6px !important;
    }

    .lon:last-child {
        padding-right: 6px !important;
    }

    .lon:first-child {
        padding-left: 6px !important;
    }
}


/* Add custom styles for the gallery container */
.custom-image-gallery {
    max-height: 200px;
    max-width: 200px;
    overflow: hidden;
}

/* Add custom styles for the images */
.image-gallery-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Change 'cover' to 'contain' to ensure image fits within the container */
}

/* Add custom styles for the navigation arrows */
.image-gallery-left-nav,
.image-gallery-right-nav {
    position: absolute;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
}

.image-gallery-left-nav {
    left: 0;
}

.image-gallery-right-nav {
    right: 0;
}

.message {
    opacity: 0;
    transition: opacity 1s;
    padding-top: 15px;
}

.message.visible {
    opacity: 1;
}

.traffic-light {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.red {
    background-color: red;
}

.yellow {
    background-color: #ffd811;
}

.green {
    background-color: green;
}


.buyers-4-wide {
    width: 265px;
    height: 420px;
    border: 1px dashed gray;
    background-color: white;
    padding: 0.5rem 1rem;
    margin-right: 1.1rem;
    margin-bottom: 1.1rem;
    cursor: move;
    float: left;

    &.no-details {
        height: 290px;
    }

    .card-body {
        padding: 0px !important;
    }

    .custom-image-gallery-item {
        position: relative;
        height: 200px;
        overflow: hidden; // This will clip any overflowing part of the image if you use object-fit: cover

        img {
            height: 100%;
            width: 100%;
            object-fit: contain; // or object-fit: cover depending on your needs
            display: block;
        }
    }
}

.buyers-5-wide {
    width: 205px;
    height: 380px;
    border: 1px dashed gray;
    background-color: white;
    padding: 0.4rem 0.8rem;
    margin-right: 1.1rem;
    margin-bottom: 1.1rem;
    cursor: move;
    float: left;

    * {
        font-size: 0.7rem !important;
    }

    &.no-details {
        height: 250px;
    }

    .card-body {
        padding: 0px !important;
    }

    .custom-image-gallery-item {
        position: relative;
        height: 175px;
        overflow: hidden; // This will clip any overflowing part of the image if you use object-fit: cover

        img {
            height: 100%;
            width: 100%;
            object-fit: contain; // or object-fit: cover depending on your needs
            display: block;
        }
    }
}

.buyers-6-wide {
    width: 170px;
    height: 355px;
    border: 1px dashed gray;
    background-color: white;
    padding: 0.4rem 0.8rem;
    margin-right: 1.1rem;
    margin-bottom: 1.1rem;
    cursor: move;
    float: left;

    * {
        font-size: 0.65rem !important;
    }

    &.no-details {
        height: 225px;
    }

    .card-body {
        padding: 0px !important;
    }

    .custom-image-gallery-item {
        position: relative;
        height: 150px;
        overflow: hidden; // This will clip any overflowing part of the image if you use object-fit: cover

        img {
            height: 100%;
            width: 100%;
            object-fit: contain; // or object-fit: cover depending on your needs
            display: block;
        }
    }
}

.buyers-7-wide {
    width: 145px;
    height: 340px;
    border: 1px dashed gray;
    background-color: white;
    padding: 0.4rem 0.8rem;
    margin-right: 1.1rem;
    margin-bottom: 1.1rem;
    cursor: move;
    float: left;

    * {
        font-size: 0.6rem !important;
    }

    &.no-details {
        height: 210px;
    }

    .card-body {
        padding: 0px !important;
    }

    .custom-image-gallery-item {
        position: relative;
        height: 125px;
        overflow: hidden; // This will clip any overflowing part of the image if you use object-fit: cover

        img {
            height: 100%;
            width: 100%;
            object-fit: contain; // or object-fit: cover depending on your needs
            display: block;
        }
    }
}

.buyers-8-wide {
    width: 125px;
    height: 320px;
    border: 1px dashed gray;
    background-color: white;
    padding: 0.4rem 0.8rem;
    margin-right: 1.1rem;
    margin-bottom: 1.1rem;
    cursor: move;
    float: left;

    *{
        font-size: 0.55rem !important;
    }

    &.no-details {
        height: 200px;
    }

    .card-body {
        padding: 0px !important;
    }

    .custom-image-gallery-item {
        position: relative;
        height: 110px;
        overflow: hidden; // This will clip any overflowing part of the image if you use object-fit: cover

        img {
            height: 100%;
            width: 100%;
            object-fit: contain; // or object-fit: cover depending on your needs
            display: block;
        }
    }
}


.card label {
    padding-left: 5px
}

.swal2-actions {
    flex-direction: row-reverse;
}

.quill {
    * {
        font-family: Poppins, cursive !important;
        font-size: 0.7rem;
    }

    background-color: #FFFFFF;
    border-radius: 0px;
    color: #565656;
    border: 1px solid grey;
    box-shadow: none;
    height: 204px;
}


.quill-edit-false {
    background-color: #F5F5F5 !important;
}

.ql-container {
    height: 160px !important;
    border: 0px !important;
}

.ql-toolbar {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
}

th {
    background: white;
    position: sticky !important ;
    top: 0 !important ; /* Don't forget this, required for the stickiness */
}

.iconDisabled {
    color: #ccc; /* Light gray for a disabled look */
}